<template>
  <div >
    <div class="container">
      <div class="item" >
        <div class="top">
          <div class="order-type">点灯祈福</div>
          <div class="order-status">已完成</div>
        </div>
        <div class="bottom">
          <div class="orderinfo">
            <span class="order-content">仙居寺庙 吉祥灯 200元</span>
            <span class="order-time">2024-1-23 15:34:30</span>
          </div>
          <div class="icon">
            <van-icon name="arrow" size="20" color="#827d78"/>
          </div>
        </div>
      </div>
    </div>
<!--    <div>-->
<!--      <van-empty description="当前无订单"/>-->
<!--    </div>-->
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
export default {
  name: 'OrderListIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      orderListData: []
    }
  },
//  计算属性 类似于data概念
  computed: {},
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {},
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.container {
  min-height: 100vh;
  padding: 20px 25px;
  background-color: #F7F7F7;
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 0 20px;
    background: #FFFFFF;
    border-radius: 4px;
    .top {
      height: 50px;
      display: flex;
      justify-content: space-between; /* 左右两边对齐 */
      align-items: center;
      border-bottom: 1px solid #eee;
      .order-type {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC sans-serif;
        font-weight: 600;
        color: rgba(0,0,0,0.85);
        line-height: 20px;
        letter-spacing: 1px;
      }
      .order-status {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC sans-serif;
        font-weight: 400;
        color: #B34341;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      .orderinfo{
        display: flex;
        flex-direction: column;
        .order-content{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC sans-serif;
          font-weight: 400;
          color: rgba(0,0,0,0.65);
          line-height: 20px;
          letter-spacing: 1px;
        }
        .order-time{
          font-size: 12px;
          font-family: PingFangSC, PingFang SC sans-serif;
          font-weight: 400;
          color: rgba(0,0,0,0.45);
          line-height: 17px;
        }
      }
      .icon{
        width: 10px;
        height: 18px;
      }
    }
  }

}
</style>
